<template>
  <div class="container" fluid="lg">
    <user-header></user-header>

    <div class="row px-2 py-4">
      <div class="col-12">
        <div class="small">{{ $t('payment_reference_number') }}</div>
        <h6 class="font-weight-bold">#{{ paymentReferenceNumber }}</h6>
        <h6
          class="text-primary text-right font-weight-bold cursor-pointer"
          @click="$bvModal.show('modal_barcode')"
          v-html="$t('view_barcode')"
        ></h6>
      </div>
    </div>

    <div class="row bg-white sticky-top">
      <nav-bar v-model="active" :nav_list="nav_list"></nav-bar>
    </div>

    <div v-if="active == 1" class="row">
      <div class="col-12 p-3">
        <h6 v-html="$t('repay_at_branch_content')"></h6>
      </div>
      <div class="col-12 text-center p-4">
        <router-link to="/find-branch">
          <b-button class="px-4" pill variant="primary">{{ $t('find_nearest_branch') }}</b-button>
        </router-link>
      </div>
    </div>

    <div v-if="active == 2" class="row">
      <div class="col-12 p-3">
        <h6 v-html="$t('repay_at_atm_content')"></h6>
      </div>
      <div class="col-12 text-center p-4">
        <router-link to="/find-atm">
          <b-button class="px-4" pill variant="primary">{{ $t('find_nearest_atm') }}</b-button>
        </router-link>
      </div>
    </div>

    <div v-if="active == 3" class="row">
      <div class="col-12 p-3">
        <h6 v-html="$t('repay_mobile_banking')"></h6>
      </div>
      <div class="col-12 text-center p-4"></div>
    </div>

    <b-modal id="modal_barcode" centered scrollable size="sm" hide-header hide-footer content-class="border-0 w-auto">
      <div class="text-center">
        <div class="p-4">
          <img :src="paymentReferencePhoto" class="w-100" />
          <div style="font-size: 11px" class="mt-4">{{ paymentReferenceNumber }}</div>
        </div>
        <!-- <barcode v-bind:value="paymentReferenceNumber" class="w-100"> </barcode> -->
        <input id="testing-code" type="hidden" :value="paymentReferenceNumber" />
        <div class="bg-white mx-auto">
          <b-button pill class="px-4 my-3" variant="primary" @click="copyToClipboard()">{{
            $t('copy_number')
          }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue'
// import VueBarcode from "vue-barcode";
import NavBar from '@/components/NavBar.vue'
import LoanService from '@/api/service/LoanService'
// import { BIconDownload } from 'bootstrap-vue'
// import { download } from '@/util/download'
export default {
  // barcode: VueBarcode
  components: {
    UserHeader,
    NavBar,
    // BIconDownload
  },
  data() {
    return {
      active: 2,
      nav_list: [
        // { key: 1, value: "online" },
        { key: 1, value: 'repay_at_branch' },
        { key: 2, value: 'repay_at_atm' },
        { key: 3, value: 'repay_mobile_banking' },
      ],
      paymentScheduleList: [],
      loanInformation: null,
    }
  },
  computed: {
    currentPeroid() {
      var period = this.paymentScheduleList.length
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          period = i
          break
        }
      }

      return period
    },
    // paymentReferenceNumber() {
    //   if (this.paymentScheduleList.length > 0) {
    //     return this.paymentScheduleList[this.currentPeroid].qrcode.string
    //   }
    //   return ''
    // },
    // paymentReferencePhoto() {
    //   if (this.paymentScheduleList.length > 0) {
    //     return this.paymentScheduleList[this.currentPeroid].qrcode.photo
    //   }
    //   return ''
    // },
    paymentReferenceNumber() {
      return this.loanInformation ? this.loanInformation.qrcode.string : ''
    },
    paymentReferencePhoto() {
      return this.loanInformation ? this.loanInformation.qrcode.photo : ''
    },
  },
  created() {
    this.getParams()
    this.getLoanInformation()
  },
  methods: {
    getParams() {
      switch (this.$route.params.type) {
        case 'branch':
          this.active = 1
          break
        case 'atm':
          this.active = 2
          break
        case 'mobile-banking':
          this.active = 3
          break
        default:
          this.active = 1
          break
      }
    },
    copyToClipboard() {
      this.$bvModal.hide('modal_barcode')
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.showAlert('ငွေပေးချေမှုရည်ညွှန်းနံပါတ်ကို ကူးယူပြီးပါပြီ')
      } catch (err) {
        // this.showAlert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    // downloadReferenceNumber() {
    //   download(this.paymentReferencePhoto, this.paymentReferenceNumber)
    // },
    async getLoanInformation() {
      try {
        this.isBusy = true
        const res = await LoanService.loanInformation()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.paymentScheduleList = loanResponse.schedule
          this.loanInformation = loanResponse
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
